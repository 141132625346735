<template>
    <base-sticky-heading>
        <template #title>
            <h5 class="text-xl font-semibold text-gray-900">
                {{ $t('myAccountant.chatAndTasks') }}
            </h5>
        </template>

        <div class="flex h-full w-full">
            <accountant-projects-detail-client-tasks-sidebar
                :tenant-id="tenantId"
                :client-task="clientTask"
                @on-select-client-task="onSelectClientTask"
            />

            <accountant-projects-detail-client-tasks-chat
                :client-task="clientTask"
                :show-client-tasks="state.showClientTasks"
                @on-show-client-task="onToggleShowClientTasks"
                @finish-create="onSelectClientTask"
            />

            <template v-if="clientTask">
                <accountant-projects-detail-client-tasks-sub-tasks
                    v-show="state.showClientTasks"
                    :client-task="clientTask"
                    @on-close-client-task="onToggleShowClientTasks"
                >
                    <template #title>
                        <h5 class="text-lg font-medium">
                            {{ $t('myAccountant.yourTasks') }}
                        </h5>
                    </template>
                </accountant-projects-detail-client-tasks-sub-tasks>
            </template>
        </div>
    </base-sticky-heading>
</template>
<script setup>
import { useClientTasks } from '@tenant/modules/accountant/practise-projects/composables/use-client-tasks'

const { tenantId } = useAuth()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()

useHead({
    title: t('myAccountant.chatAndTasks'),
})

const state = reactive({
    showClientTasks: false,
})

const { clientTasks } = useClientTasks()

const clientTask = computed(() => {
    const { task_id } = route.query
    if (!task_id) {
        return null
    }

    return clientTasks.value?.find((i) => i.id === task_id) || null
})

const onToggleShowClientTasks = () => {
    state.showClientTasks = !state.showClientTasks
}

const onSelectClientTask = (clientTask) => {
    router.replace({
        query: {
            task_id: clientTask.id,
        },
    })
}
</script>
