import { GUARD } from '@tenant/utils/constants'
import { useApi } from '@tenant/composables'

const routes = [
    {
        name: 'my-accountant.tasks',
        path: '/my-accountant/tasks',
        component: () => import('./pages/my-accountant-tasks.vue'),
        beforeEnter: async () => {
            const { execute, result: accountants } = useApi(
                '/api/tenant-accountants',
                'GET'
            )
            await execute()

            if (accountants.value.length === 0) {
                return { name: 'my-accountant.connect' }
            }
        },
    },
    {
        name: 'my-accountant.connect',
        path: '/my-accountant/connect',
        component: () => import('./pages/my-accountant-connect.vue'),
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: [GUARD.TENANT, GUARD.SUBSCRIPTION],
        permission: 'view_my_accountant',
    },
}
