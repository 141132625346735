const navigation = [
    {
        id: 'sas-my-accountant',
        path: 'my-accountant',
        label: 'myAccountant.title',
        icon: 'line-icons:education:calculator',
        position: 90,
        permission: 'view_my_accountant',
    },
    {
        id: 'sas-my-accountant-tasks',
        path: 'my-accountant.tasks',
        label: 'myAccountant.tasks',
        parent: 'sas-my-accountant',
        position: 2,
    },
]

export default navigation
